import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { imageURL } from "../../../constants";
import Menus from "./Menus";
import TopHeader from "./TopHeader";
import { DrtContext } from "../../../context/DrtContext";
//import { NavLink } from "react-router-dom";
import ExternalLinkPopup from "../../../services/ExternalLinkPopup";

const Header = () => {
  const {
    externalLink,
    source,
    setSource,
    setExternalLink,
    setExternalLinkPopupShow,
  } = useContext(DrtContext);
  return (
    <div className="wraper">
      {/* <div className="preloader">
         <div className="spinner"></div>
      </div>   */}

      <TopHeader />
      <div className="logo-sec-wraper">
        <div className="container">
          <div className="row justify-content-between flex-wrap align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7 logo-sec">
              <NavLink to="/" className="logo-align">
                <img src={`${imageURL}/emblem-dark.png`} alt="emblem" />
                <div className="brand-text">
                  <h4>
                    DEBTS RECOVERY APPELLATE TRIBUNALS (DRATs)
                    <span>DEBTS RECOVERY TRIBUNALS (DRTs)</span>
                  </h4>
                </div>
              </NavLink>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-5 logo-sec text-right">
              <a
                // href={`"https://swachhbharat.mygov.in/"`}
                className="external-link"
                // target={"_blank"}
                onClick={() => {
                  setExternalLinkPopupShow(true);
                  setExternalLink("https://swachhbharat.mygov.in/");
                  setSource("Header");
                }}
              >
                <img src="./images/swach-bharat.png" alt="swach-bharat" />
              </a>
              <img
                src="./images/aazadi-ka-amrit-mohotsav.png"
                alt="aazadi-ka-amrit-mohotsav"
              />
            </div>
          </div>
        </div>
      </div>

      {externalLink !== "" && source === "Header" ? (
        <ExternalLinkPopup />
      ) : null}

      <Menus />
    </div>
  );
};

export default Header;
