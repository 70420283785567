import React, { useContext } from "react";
import { imageURL } from "../../../constants";
import Latestnews from "./latestupdate";
import MinisterComponent from "./minister";
import Homeslider from "./homeSlider";
import Welcome from "./Welcome";
import Notices from "./Notices";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import DrtCaseCauseParent from "./DrtCaseCauseParent/DrtCaseCauseParent";
import WhatsNew from "./WhatsNew/WhatsNew";
import { DrtContext } from "../../../context/DrtContext";
import ExternalLinkPopup from "../../../services/ExternalLinkPopup";
import { useTitle } from "../../../services/PageTitle";

const Home = () => {
  useTitle("DRATs")
  const {
    externalLink,
    source,
    setSource,
    setExternalLink,
    setExternalLinkPopupShow,
  } = useContext(DrtContext);
  const settings = {
    //dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="homepage">
        <div className="homecontent-sec">
          <div className="main-bg">
            <div className="container pt-4">
              <div className="sub-bg">
                <div className="row w-100 align-items-center">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                    <Latestnews />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-8 p-0">
                    <MinisterComponent />
                  </div>
                </div>
              </div>

              <Homeslider />

              <div className="sub-bg2">
                <div className="row">
                  <DrtCaseCauseParent />
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                    <WhatsNew />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container" id="wel-wraper">
            <Welcome />
          </div>

          <Notices />

          <div className="bottom-slide">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <ul>
                    <li>
                      <NavLink to="/gallery">
                        <span>
                          <img src={`${imageURL}/gallery.png`} alt="gallery" />
                        </span>
                        Gallery
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/tender">
                        <span>
                          <img src={`${imageURL}/tender.png`} alt="tender" />
                        </span>
                        Tender
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/vacancies">
                        <span>
                          <img
                            src={`${imageURL}/vacancies.png`}
                            alt="vacancies"
                          />
                        </span>
                        Vacancies
                      </NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink to="13">
                        <span>
                          <img src={`${imageURL}/pressz.png`} alt="press release"/>
                        </span>
                        Press Release
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink to="/actnrule">
                        <span>
                          <img src={`${imageURL}/act.png`} alt="act" />
                        </span>
                        Acts & Rules
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/downloads">
                        <span>
                          <img
                            src={`${imageURL}/download.png`}
                            alt="download"
                          />
                        </span>
                        Downloads
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/usefullink">
                        <span>
                          <img src={`${imageURL}/links.png`} alt="links" />
                        </span>
                        Useful Links
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/rti">
                        <span>
                          <img src={`${imageURL}/rti.png`} alt="rti" />
                        </span>
                        RTI
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/organizationalstructure">
                        <span>
                          <img
                            src={`${imageURL}/organization-icon.png`}
                            alt="organizational structure"
                          />
                        </span>
                        Organizational Structure
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="gray-bg">
            <div className="container">
              <div className="row mt-3">
                <div className="col-12">
                  <div id="footer-gov1" className="govlink-carousel">
                    <Slider {...settings}>
                      <a
                        // href="https://india.gov.in/"
                        className="external-link"
                        // target={"_blank"}
                        onClick={() => {
                          setExternalLinkPopupShow(true);
                          setExternalLink("https://india.gov.in/");
                          setSource("Home.jsx");
                        }}
                      >
                        <img
                          src={`${imageURL}/f1.png`}
                          className=""
                          alt="india gov"
                        />
                      </a>
                      <a
                        // href=" https://goidirectory.nic.in"
                        className="external-link"
                        onClick={() => {
                          setExternalLinkPopupShow(true);
                          setExternalLink("https://goidirectory.nic.in");
                          setSource("Home.jsx");
                        }}
                        // target={"_blank"}
                      >
                        <img
                          src={`${imageURL}/f2.png`}
                          className=""
                          alt="go india"
                        />
                      </a>
                      <a
                        // href=" https://data.gov.in/"
                        className="external-link"
                        onClick={() => {
                          setExternalLinkPopupShow(true);
                          setExternalLink("https://data.gov.in/");
                          setSource("Home.jsx");
                        }}
                        // target={"_blank"}
                      >
                        <img src="images/f3.png" className="" alt="data gov" />
                      </a>
                      <a
                        // href="https://www.makeinindia.com/"
                        className="external-link"
                        onClick={() => {
                          setExternalLinkPopupShow(true);
                          setExternalLink("https://www.makeinindia.com/");
                          setSource("Home.jsx");
                        }}
                        // target={"_blank"}
                      >
                        <img
                          src={`${imageURL}/f4.png`}
                          className=""
                          alt="make in india"
                        />
                      </a>

                      <a
                        // href="https://india.gov.in/"
                        className="external-link"
                        onClick={() => {
                          setExternalLinkPopupShow(true);
                          setExternalLink("https://india.gov.in/");
                          setSource("Home.jsx");
                        }}
                        // target={"_blank"}
                      >
                        <img
                          src={`${imageURL}/f1.png`}
                          className=""
                          alt="india"
                        />
                      </a>
                      <a
                        // href="https://mygov.in/"
                        className="external-link"
                        onClick={() => {
                          setExternalLinkPopupShow(true);
                          setExternalLink("https://mygov.in/");
                          setSource("Home.jsx");
                        }}
                        // target={"_blank"}
                      >
                        <img
                          src={`${imageURL}/f5.png`}
                          className=""
                          alt="mygov"
                        />
                      </a>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {externalLink !== "" && source === "Home.jsx" ? (
            <ExternalLinkPopup />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Home;
