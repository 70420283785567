import React, { useContext } from "react";
import { useState } from "react";
import BredCrumb from "./BredCrumb";
import InternalBaseBanner from "./InternalBaseBanner";
import axiosBaseURL from "../../httpCommon";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { DrtContext } from "../../context/DrtContext";
import { useTitle } from "../../services/PageTitle";

const RTI = () => {
  useTitle("RTI");
  const { EncryptDecrypt } = useContext(DrtContext);
  const [loading, setLoading] = useState(true);
  const [rtilistdata, setrtigetdata] = useState([]);

  const rtilist = async () => {
    await axiosBaseURL.get("/get-drtcategory/RTI").then((result) => {
      const temp = EncryptDecrypt(result.data);
      setrtigetdata(temp.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    rtilist();
  }, []);

  if (loading) {
    return <h6 className="loader">Loading......</h6>;
  }

  // console.log(rtilistdata);

  return (
    <>
      <InternalBaseBanner title={"RTI"} />
      <BredCrumb title={"RTI"} />
      <div className="common-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="about-content">
                <h6>RTI</h6>
                <ul className="navbar-nav mr-auto menu mt-4 mb-5">
                  {rtilistdata.map((item, index) => (
                    <li className="nav-item" key={index}>
                      <NavLink to={`/rtidetail/${item.id}`}>
                        {item.categories_name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RTI;
